.container {
    display: flex;
    span {
        display: flex;
        a {
            color: var(--primaryColor500);
            text-decoration: none;
            font-weight: 500;
            font-size: 14px;
        }

        svg {
            margin-top: 4px;
            color: var(--primaryColor500) !important;
            font-weight: 700;
            font-size: 14px;
        }


        p {
            padding: 0;
            margin: 0;
            margin-top: 2px;
            color: var(--primaryColor500);
            font-weight: 700;
            font-size: 14px;
        }
    }


}