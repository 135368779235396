.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;

    .pageTitle {
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0;
    }
    .actionBtns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        width: 50%;
        .btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1rem;
            width: 50%;
            button{
                gap: 10px;
            }
        }
    }
}

@media screen and (max-width:1550px) {
    .header {
        .pageTitle {
            font-size: 1.2rem;
        }
        .actionBtns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1rem;
            width: 70%;
            .btns {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                gap: 1rem;
                width: 50%;
                button{
                    gap: 10px;
                }
            }
        }
    }
}