.container,
.undoRequestData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
    gap: 1rem;

    .error {
        color: var(--errorColor900);
        font-weight: 500;
        text-align: center;
    }

    .confirmData {
        gap: 5px;
        text-align: center;

        .name {
            color: var(--primaryColor700);
            font-size: 20px;
            font-weight: 500;
        }

        .aId {
            color: var(--primaryColor500);
            font-size: 16px;
            font-weight: 400;
        }

        .ticketNumber {

            color: var(--primaryColor500);
            font-size: 16px;
            font-weight: 700;

            span {
                color: var(--primaryColor500);
                font-size: 16px;
                font-weight: 400;
            }
        }


    }

    .actionType {
        color: var(--primaryColor700);
        font-size: 14px;
        font-weight: 700;
        // margin-top: 8px;
    }

    .confirm {
        color: var(--errorColor900);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .noteContainer {
        display: flex;

        .note {
            color: var(--errorColor900);
            font-weight: 700;
        }

        .noteMessage {
            font-weight: 400;
            color: var(--primaryColor500);

        }

    }

    .btnsContainer {
        display: flex;
        justify-content: center;
        width: 98%;

        .yesBtn {
            width: 100%;

            button {
                border-radius: 10px;
                border: 1px solid var(--errorColor900);
                color: var(--errorColor900);
                font-weight: 700;
                text-align: center;
                padding: .7rem .3rem;
                width: 86%;
                background-color: #FFF;

                &:hover {
                    background-color: var(--errorColor900);
                    color: #FFF;
                }
            }

        }

        .btn {
            width: 100%;

        }
    }

    .error {
        color: var(--errorColor900);
        font-weight: 500;
    }

}