.sideMenu,
.closedsideMenu {
    height: 100%;
    padding: 0 20px;
    border: 1px solid var(--primaryColor400);
    border-radius: 10px;
    transition: all 250ms ease-in-out;
    .close {
        display: none;
    }
    small {
        margin-bottom: 0.6rem;
        margin-top: 1rem;
        display: inline-block;
        color: var(--grayColor800);
        font-weight: 500;
    }
    .active {
        display: flex;
        cursor: pointer;
        gap: 10px;
        margin-bottom: 10px;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--primaryColor);
        padding: 14px 10px;
        padding-left: 20px;
        color: #fff;
        border-radius: 10px;
        font-weight: 500;
        p,
        svg {
            color: #fff;
            margin-bottom: 0 !important;
            font-weight: 600;
        }
        svg {
            font-size: 20px;
        }
    }
    .smActive {
        display: flex;
        cursor: pointer;
        gap: 10px;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        background-color: var(--primaryColor);
        padding: 14px;
        padding-left: 20px;
        color: #fff;
        border-radius: 10px;
        font-weight: 500;
        p,
        svg {
            color: #fff;
            margin-bottom: 0 !important;
            font-weight: 600;
        }
        svg {
            font-size: 20px;
        }
    }
    .unActive {
        display: flex;
        cursor: pointer;
        gap: 10px;
        margin-bottom: 10px;
        justify-content: flex-start;
        align-items: center !important;
        background-color: #fff;
        padding: 14px 10px;
        padding-left: 20px;
        border-radius: 10px;
        color: var(--grayColor500);
        font-weight: 500;
        p,
        svg {
            color: var(--greyColor800);
            margin-bottom: 0 !important;
        }
        svg {
            font-size: 20px;
        }
    }
}
@media screen and (max-width: 1366px) {
    .sideMenu,
    .closedsideMenu {
        .active {
            font-size: 12px;
            p,
            svg {
                color: #fff;
                margin-bottom: 0 !important;
                font-weight: 600;
            }
            svg {
                font-size: 20px;
            }
        }
        .smActive {
            font-size: 12px;
            p,
            svg {
                color: #fff;
                margin-bottom: 0 !important;
                font-weight: 600;
            }
            svg {
                font-size: 20px;
            }
        }
        .unActive {
            font-size: 12px;

            p,
            svg {
                color: var(--greyColor800);
                margin-bottom: 0 !important;
            }
            svg {
                font-size: 20px;
            }
        }
    }
}
// @media screen and (max-width:1250px) {
//     .sideMenu {
//         position: fixed;
//         transition: all 250ms ease-in-out;
//         top: 0;
//         left: 0;
//         height: 100%;
//         max-width: 300px;
//         width: 100%;
//         background-color: #fff;
//         z-index: 999;
//         padding: 0 20px;
//         border: 1px solid var(--primaryColor400);
//         border-radius: 10px;
//         .close {
//             display: block;
//             margin-left: auto;
//             border: none;
//             background-color: transparent;
//             color: #000;
//             font-size: 1.5rem;
//             margin-top: 1rem;
//             margin-bottom: 2rem;
//             cursor: pointer;
//         }
//         small {
//             color: var(--grayColor800);
//         }
//         .active {
//             display: flex;
//             cursor: pointer;
//             gap: 10px;
//             margin-bottom: 10px;
//             justify-content: flex-start;
//             align-items: center;
//             background-color: var(--primaryColor);
//             padding: 14px 10px;
//             padding-left: 20px;
//             color: #fff;
//             border-radius: 10px;
//             font-weight: 500;
//             p,
//             svg {
//                 color: #fff;
//                 margin-bottom: 0 !important;
//                 font-weight: 600;
//             }
//             svg {
//                 font-size: 20px;
//             }
//         }
//         .unActive {
//             display: flex;
//             cursor: pointer;
//             gap: 10px;
//             margin-bottom: 10px;
//             justify-content: flex-start;
//             align-items: center !important;
//             background-color: #fff;
//             padding: 14px 10px;
//             padding-left: 20px;
//             border-radius: 10px;
//             color: var(--grayColor500);
//             font-weight: 500;
//             p,
//             svg {
//                 color: var(--greyColor800);
//                 margin-bottom: 0 !important;
//             }
//             svg {
//                 font-size: 20px;
//             }
//         }
//     }
//     .closedsideMenu {
//         position: fixed;
//         transition: all 250ms ease-in-out;
//         top: 0;
//         left: -100%;
//         height: 100%;
//         max-width: 300px;
//         width: 100%;
//         background-color: #fff;
//         z-index: 999;
//         padding: 0 20px;
//         border: 1px solid var(--primaryColor400);
//         border-radius: 10px;
//         .close {
//             display: block;
//             margin-left: auto;
//             border: none;
//             background-color: transparent;
//             color: #000;
//             font-size: 1.5rem;
//             margin-top: 1rem;
//             margin-bottom: 2rem;
//             cursor: pointer;
//         }
//         small {
//             color: var(--grayColor800);
//         }
//         .active {
//             display: flex;
//             cursor: pointer;
//             gap: 10px;
//             margin-bottom: 10px;
//             justify-content: flex-start;
//             align-items: center;
//             background-color: var(--primaryColor);
//             padding: 14px 10px;
//             padding-left: 20px;
//             color: #fff;
//             border-radius: 10px;
//             font-weight: 500;
//             p,
//             svg {
//                 color: #fff;
//                 margin-bottom: 0 !important;
//                 font-weight: 600;
//             }
//             svg {
//                 font-size: 20px;
//             }
//         }
//         .unActive {
//             display: flex;
//             cursor: pointer;
//             gap: 10px;
//             margin-bottom: 10px;
//             justify-content: flex-start;
//             align-items: center !important;
//             background-color: #fff;
//             padding: 14px 10px;
//             padding-left: 20px;
//             border-radius: 10px;
//             color: var(--grayColor500);
//             font-weight: 500;
//             p,
//             svg {
//                 color: var(--greyColor800);
//                 margin-bottom: 0 !important;
//             }
//             svg {
//                 font-size: 20px;
//             }
//         }
//     }
// }
