.container {
    height: calc(100vh - 150px);
    padding-left: 180px;

    .mainPage {
        display: flex;
        width: 100%;
        gap: 2rem;
        .sideMenu {
            width: 100%;
            min-height: calc(100vh - 220px);
            height: fit-content;
            max-width: 350px;
            background-color: var(--whiteColor);
            border-radius: 10px;
            border: 1px solid var(--Grey-70, #e8e8e8);
            margin-bottom: 1rem;
            position: fixed;
            left: 150px;
            top: 100px;
        }

        .mainData {
            width: 100%;
            .pagesInfo{
                margin-top: 1rem;
            }
        }
    }
}

@media screen and (max-width:1366px) {
    .container {
        .mainPage {
           
            .sideMenu {
                max-width: 250px;
          
            }
           
        }
    }
}