.modal {
    overflow: hidden !important;
    direction: ltr;
    .modalHeader {
        color: var(--primaryColor) !important;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto !important;
        }
    }

    .closeBtn {
        display: flex;
        justify-content: flex-end;
        padding: 0px 10px;
        margin-top: 40px;
        cursor: pointer;

        img {
            color: var(--greyColor200);
            font-size: 25px;
            height: 30px;
            width: 30px;
            object-fit: contain;
            margin-right: -20px;
        }
    }

    .title {

        color: var(--primaryColor700);

    }
}

.rtlModal {
    overflow: hidden !important;
    direction: rtl !important;
    text-align: right;

    .modalHeader {
        color: var(--primaryColor) !important;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: calc(-0.5 * var(--bs-modal-header-padding-y)) auto calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) !important;
        }
    }
}