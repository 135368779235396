header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        padding-left: 2rem;
        width: 350px;
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            p {
                font-weight: 700;
                font-size: 23px;
                color: var(--primaryColor);
                font-family: "DM Sans";
                margin: 0;
            }
        }
        .icon {
            font-size: 25px;
            color: var(--grayColor400);
            cursor: pointer;
        }
    }
    .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 2rem;
        gap: 15px;
        svg {
            cursor: pointer;
        }
        div {
            display: flex;
            justify-content: center;
            align-items: center;

            .userInfo {
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
