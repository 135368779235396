.profileInfoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .userInfo {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
        padding: 0 6%;

        .name {
            color: black;
            font-weight: 700;
            font-size: 16px;
        }

        .aId {
            color: var(--primaryColor500);
            padding: 0 1%;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;

            span {
                color: var(--primaryColor700);
            }
        }
    }
}