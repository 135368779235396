.button {
    background-color: #fff;
    color: var(--primaryColor) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--primaryColor);
    width: 100%;
    padding: 2.5% 0;
    border-radius: 0.375rem;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 18px;

    &:hover {
        background-color: var(--primaryColor) !important;
        color: #fff !important;
    }
    &:disabled {
        background-color: rgb(153, 153, 153) !important;
        border-color: rgb(153, 153, 153) !important;
        color: #fff !important;
        cursor: not-allowed;
    }
}