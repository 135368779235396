.layout {
    .header {
        height: 80px;
        background-color: var(--whiteColor);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
    padding-top: 80px;
    .main {
        display: flex;
        width: 100%;
        padding-top: 20px;
        padding: 20px;
        padding-left: 320px;
        gap: 1rem;
        .sideMenu {
            transition: all 250ms ease-in-out;
            width: 100%;
            max-width: 300px;
            height: calc(100vh - 150px);
            background-color: #fff;
            position: fixed;
            left: 16px;
        }
        .pages {
            width: 100%;
            // height: calc(100vh - 130px);
            padding: 0 1rem;
            overflow-x: hidden;
        }
    }
}

@media screen and (max-width: 1366px) {
    .layout {
     
        .main {
            display: flex;
            width: 100%;
            padding-top: 20px;
            padding-left: 220px;

            .sideMenu {
                transition: all 250ms ease-in-out;
                width: 100%;
                max-width: 200px;
                height: calc(100vh - 150px);
                background-color: #fff;
            }
            .pages {
                // width: 100%;
                // height: calc(100vh - 130px);
                // padding: 0 1rem;
                // padding-right: 1rem;
                height: 100%;
                width: 100%;
            }
        }
    }
    
}
