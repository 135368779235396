.container,
.ticketInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ticketInfo {
        width: 83%;

        .name {
            color: var(--primaryColor700);
            font-size: 16px;
            font-weight: 700;
        }

        .aId {
            color: #777E89;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        .ticketNumber {

            color: var(--primaryColor500);
            font-size: 16px;
            font-weight: 700;

            span {
                color: var(--primaryColor500);
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    .btn,
    .select,
    .comment {
        width: 83%;
    }
    .btn {
        margin-top: 5px;
    }

}