.ticketInfoContainer {
    border-radius: 10px;
    border: 1px solid var(--grayColor700);
    padding: 1.5rem;
    gap: 1rem;

    .ticketInfo {
        //padding-top: 1rem;
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            gap: 0.8rem;
            justify-content: center;
            align-items: center;
        }

        .name {
            color: #114b7a;
            font-weight: 700;
        }

        .aId {
            color: var(--grayColor500);
            font-size: 11px;
            font-weight: 400;
        }

        .date,
        .source {
            color: var(--grayColor600);
            font-size: 12px;
            font-weight: 700;
        }

        .ticketNumber {
            color: #2374bb;
            font-size: 12px;
            font-weight: 700;

            span {
                font-weight: 400;
            }
        }
    }

    .description {
        padding-top: 1rem;
        color: #666666;
        font-size: 12px;
        font-weight: 500;
        width: 350px;
        .ticketReason
        {
            font-weight: 600;
        }
    }
}
