@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Inter:wght@400;700&family=Pacifico&family=Buenard&family=Playfair+Display&family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap');

body {
  margin: 0 !important;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbfb !important;
}
* {
  box-sizing: border-box !important;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p{
  color: var(--primaryColor700);
  padding: 0;
  margin: 0;
}

small, span{
  font-family: "Poppins";
}

:root {
  --primaryColor: #1b4b7a;
  --primaryColor1100: #114B7A;
  --primaryColor1000: #FFE8E8;
  --primaryColor900: #E8F1FF;
  --primaryColor800: #00163a;
  --primaryColor700: #183a59;
  --primaryColor600: #EDF7FF;
  --primaryColor500: #2374bb;
  --primaryColor400: #ecf0f2;
  --primaryColor300: #e0ebf5;
  --primaryColor200: #c4e0f5;
  --primaryColor100: #73a2cc;

  --greyColor: #F0F0F0;
  --whiteColor:#ffffff;
  --grayColor100: #e9ecef;
  --grayColor200: #cdd0d3;
  --grayColor300: #d5d5d5;
  --grayColor400: #949DB2;
  --grayColor500: #8C8C8C;
  --grayColor600: #666666;
  --grayColor700: #e8e8e8;
  --grayColor800: #bebebe;
  --grayColor900: #99ABB4;
  --grayColor1000: #828282;
  --warningColor: #fbc012;
  --warningColor400: rgba(251, 192, 18, 0.2);
  --warningColor100: #f6c01238;
  --warningColor200: #f6c012;
  --warningColor300: #fffadf;
  --successColor: #008000;
  --successColor100: rgba(44, 211, 61, 0.1);
  --successColor200: #e5f6ff;
  --successColor300: #c7e9ca;
  --successColor400: #dfffe4;
  --successColor500: #15a424;
  --errorColor: #ff0000;
  --errorColor100: rgba(255, 50, 50, 0.1);
  --errorColor200: rgb(206, 44, 44, 0.3);
  --errorColor300: rgb(206, 44, 44);
  --errorColor700: #d30000;
  --errorColor800: #ffdede;
  --errorColor900: #cd2b2b;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:var(--primaryColor700);
  border-radius: 10px;
}


.MuiTooltip-popper {
  z-index: 9999999 !important;
}

.rdt_Table {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 100% !important;
    height: 0.5em;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primaryColor);
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: var(--primaryColor700);
      cursor: pointer;
    }
  }
}
.dropdownMenu {
  a {
    &:active {
      background-color: var(--grayColor300) !important;
    }
  }
}
.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid var(--grayColor100) !important;
}
.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  min-height: 300px;
}

.rdt_TableRow {

  &:hover {
    outline-style: none !important;

    
  }
}

.rdt_TableCell {
  text-align: center;


}

.rdt_TableRow {
  // page-break-inside: avoid;
  // display: table-row-group !important;
  page-break-inside: avoid;
  page-break-after: auto;
  &:hover {
    outline-style: none !important;

    .rdt_TableCell {
      text-align: center;
      background-color: var(--grayColor100) !important;
    }
  }
}

.modal-backdrop.show {
  z-index: 9999 !important;
}
.modal-header {
  border-bottom: 0 !important;
  margin: 0 !important;
}

.modal-content {
  border-radius: 25px !important;
}

.modal-body {
  padding: 0rem 3rem !important;
  padding-bottom: 3rem !important;
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media print {
  .rdt_TableBody {
    display: block !important;
    width: auto !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
  }

  .rdt_Table {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 100% !important;
      height: 0.4em;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primaryColor);
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: var(--primaryColor700);
        cursor: pointer;
      }
    }
  }
}

.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.accordion-item {
  border: none !important;
  background-color: transparent !important;
}

.accordion-header {
  padding: 1% !important;
  max-width: fit-content !important;

  button {
    color: var(--primaryColor);
    font-weight: bold;
    padding: 2% 0;
    white-space: nowrap !important;
  }

  button::after {
    color: var(--primaryColor);
    margin: 0 10% !important;
  }
}

.accordion-button {
  background-color: transparent !important;
  white-space: nowrap !important;
}

.accordion-button:focus {
  white-space: nowrap !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  white-space: nowrap !important;
  color: var(--primaryColor) !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: bold;
  padding: 2% 0;
}

.accordion-button:not(.collapsed)::after {
  color: var(--primaryColor);
  margin: 0 10% !important;
  background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-body {
  padding: 0 !important;
}

.apexcharts-toolbar{
  display: none !important;
}