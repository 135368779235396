.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
    transition: width 0.8s;
    width: 5rem;
    max-width: 250px;

    &:hover {
        width: 100%;
    }

    &:focus {
        width: 100%;
    }

    .searchContainer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        width: 100%;
        max-width: 250px;

        .searchInput {
            width: 100%;
            padding-bottom: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 2;
            color: var(--primaryColor700);
            background-color: hsl(0, 0%, 100%);
            background-clip: padding-box;
            border: 1px solid hsl(0, 0%, 80%);
            appearance: none;
            border-radius: 0.375rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            padding: 0px 10px;

            &:focus {
                color: var(--primaryColor700);
                background-color: #fff;
                border-color: #86b7fe;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
            }

            &:hover {
                border-color: hsl(0, 0%, 70%);
            }
        }
    }

    .search {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        animation: fadeIn 0.5s linear;

        svg {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            margin: 0 1%;
            z-index: 100;
            width: 2rem;
            height: 1.6rem;
            color: var(--primaryColor);

            &:hover {
                color: var(--grayColor500);
            }
        }
    }

    .glass {
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid var(--primaryColor700);

        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: fadeIn 0.5s linear;
            color: var(--primaryColor);
            width: 1.3rem;
            height: 1.3rem;

            &:hover {
                color: var(--primaryColor700);
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
