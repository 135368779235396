.sideMenu{
    padding: 1rem;
    small{
        margin-bottom: 0.6rem;
        margin-top: 1rem;
        display: inline-block;
        color: var(--grayColor600);
        font-weight: bold;
    }
    p{
        color: var(--grayColor500);
        padding: 1.3rem 0;
        padding-left: 2rem;
        font-weight: 500;
        border-radius: 10px;
        margin-bottom: 0;
        cursor: pointer;
    }

    .activeTab{
        background-color: var(--primaryColor600);
        color: var(--primaryColor);
        font-weight: 600;
    }
    .disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: var(--grayColor400);
    }
}

@media screen and (max-width:1366px) {
    .sideMenu{
     
        p{
          
            font-size: 14px;
          
        }
    
    
    
    }
}