.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    p {
        margin: 0;
        padding-top: 1rem;
        color: var(--primaryColor700);
        font-weight: 500;
        text-align: center;
    }

    .actions {
        padding: 2%;
        width: 80%;

        div {
            padding-top: 2%;

            button {
                svg {
                    margin-right: 8px;
                }
            }
        }
    }
}